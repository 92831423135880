@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~@fortawesome/fontawesome-free/css/all.css';

.page-wrapper.toggled .sidebar {
  left: -300px;
}
.sidebar .ps.ps--scrolling-y > .ps__rail-y, .sidebar > .ps.ps--scrolling-x > .ps__rail-x, .ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
}
.sidebar .ps.ps--active-y > .ps__rail-y:hover, .sidebar > .ps.ps--active-y > .ps__rail-y.ps--clicking {
  width: 10px !important;
}
.sidebar .ps__thumb-y {
  background-color: #fff !important;
  width: 6px !important;
  border-radius: 1px !important;
  opacity: 0.5;
}

#general-scrollbar .ps__thumb-y {
  background-color: #000 !important;
}

body{
  background-color: #d6d6d6;
}

.card{
  border-radius: 8px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.08), 0 10px 10px rgba(0,0,0,0.06);
}

.newButton{
  z-index: 3000;
  text-transform: uppercase;
  text-decoration: none !important;
  color: #fff;
  border-radius: 50px;
  position: fixed;
  bottom: 40px;
  left: 50%;
  margin-left: -190px;
  font-weight: bold;
  width: 380px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(59, 165, 93);
  border: none;
  transition: 0.1s;
}

.newButton:hover{
  color: #fff;
  background-color: rgb(69, 194, 109);
  font-size: 17px;
  height: 64px;
  bottom: 37px;
  width: 400px;
  margin-left: -200px;
}

.btn{
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  min-height: 40px;
  width: 160px;
}

.btn:hover{
  opacity: 0.7;
}


.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  outline: none;
}

.switch input {
  outline: none;
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  outline: none;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.internal-card-container {
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.loading{
  background: #000;
  opacity: 0.06;
}

.hidden-item{
  display: none;
}

.overlay{
  background: #000;
  opacity: 0.06;
}

tbody th:first-child {
  border: none;
}

tbody {
  border: none !important;
}

thead > tr > th {
  border: none;
  box-shadow: none;
}

tbody > tr > td {
  border: none;
}

tbody > tr > th {
  border: none;
  box-shadow: none;
}

thead > tr > td{
  border: none;
  box-shadow: none;
}

table th:first-child{
  border-radius:10px 0 0 10px;
}

table th:last-child{
  border-radius:0 10px 10px 0;
}

.ng-autocomplete {
  margin-bottom: 0.5rem;
  width: calc(100% - 1rem) !important;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.ng-autocomplete label {
  margin-bottom: 0.5rem;
}

